import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { homepageFlex, aboutUs } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const IndexPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="NanoPharmaceuticals Homepage" />
      <RunReCaptcha action="homepage" />

      <div className={homepageFlex}>
        <div className={aboutUs}>
          <h2>
            NanoPharmaceuticals LLC is an emerging pharmaceutical company
            devoted to the discovery, development and commercialization of
            innovative, molecularly targeted compounds for diseases with
            significant unmet needs.
          </h2>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
